const moduleRoute = {
  path: "/payroll",
  name: "payroll",
  meta: {
    breadcrumb: {
      label: 'Payroll'
    },
    module: "payroll",
    requiresAuth: true,
    title: "Razayya Financial | Insights | Payroll"
  },
  redirect: '/payroll/employees',
  component: () => import(/* webpackChunkName: "payroll" */ "./payrollModule.vue"),
  children: [
    {
      path: "stores",
      name: "payroll.stores",
      meta: { 
        showInNav: true,
        breadcrumb: {
          label: 'Stores',
          parent:'payroll'
        },
        icon: 'mdi-store'
      },
      component: () => import (/* webpackChunkName: "stores" */ "./views/StoresListView.vue"),
    },
    {
      path: "stores/:id",
      name: "payroll.store-detail",
      meta: { breadcrumb: 
        {
          label: 'Store Details',
          parent: 'payroll.stores'
        } 
      },
      component: () => import (/* webpackChunkName: "store-details" */ "./views/StoreDetailView.vue"),
    },
    {
      path: "employees",
      name: "payroll.employees",
      meta: { 
        showInNav: true,
        breadcrumb: 
        {
          label: 'Employees',
          parent:'payroll'
        },
        icon: 'mdi-account-group'
      },
      component: () => import (/* webpackChunkName: "employees" */ "./views/EmployeeListView.vue")
    },
    {
      path: "employees/:employeeId",
      name: 'payroll.employee-detail',
      meta: {
        breadcrumb:{
          label: 'Employee Details',
          parent: 'payroll.employees'
        }
      },
      component: () => import(/* webpackChunkName: "employee-details" */ "./views/EmployeeDetailView.vue")
    },
    {
      path: "pay-runs",
      name: "payroll.pay-runs",
      meta: { 
        showInNav: true,
        breadcrumb: 
        {
          label: 'Pay Runs',
          parent:'payroll'
        },
        icon: 'mdi-cash'
      },
      component: () => import (/* webpackChunkName: "payruns" */ "./views/PayRunsListView.vue")
    },
    {
      path: "pay-runs/:payRunId",
      name: "payroll.pay-runs-detail",
      meta: { breadcrumb: 
        {
          label: 'Pay Run Details',
          parent:'payroll.pay-runs'
        } 
      },
      component: () => import (/* webpackChunkName: "payrun-detail" */ "./views/PayRunDashboardView.vue")
    },
    {
      path: 'pay-runs/:payRunId/employee/:employeeId',
      name: 'payroll.pay-run.employee-detail',
      component: () => import ("./views/EmployeePayRunDetailView.vue"),
      meta: {
        breadcrumb:{
          label:'Employee Payroll Detail',
          parent:'payroll.pay-runs-detail'
        }
      }
    },
    {
      path: 'employee/:employeeId/pay-runs/:payRunId',
      name: 'payroll.employee.pay-run-detail',
      component: () => import ("./views/EmployeePayRunDetailView.vue"),
      meta: {
        breadcrumb:{
          label:'Employee Payroll Detail',
          parent:'payroll.employee-detail'
        }
      }
    },
    {
      path: "metrics",
      name: "payroll.metrics",
      component: () => import ( /* wepackChunkName: 'metrics" */ "./views/MetricsListView.vue" ),
      meta: { 
        showInNav: true,
        breadcrumb: 
        {
          label: 'Metrics',
          parent:'payroll'
        },
        icon: 'mdi-finance' 
      }
    },
    {
      path: "metrics/:id",
      name: "payroll.metrics.details",
      component: () => import ( /* wepackChunkName: 'metrics" */ "./views/MetricDetailView.vue" ),
      meta: { breadcrumb: 
        {
          label: 'Metric Details',
          parent:'payroll.metrics'
        } 
      }
    },
    {
      path: "holidays",
      name: "payroll.holidays",
      component: () => import(/* webpackChunkName: "holidays" */ "./views/HolidaysListView.vue"),
      meta: { 
        showInNav: true,
        breadcrumb: {
          label: 'Holidays',
          parent: 'payroll'
        },
        icon: 'mdi-calendar'
      },
    },
    {
      path: "settings",
      name: "payroll.settings",
      meta: { breadcrumb: 
        {
          label: 'Settings',
          parent:'payroll'
        },
        icon: 'mdi-cogs'
      },
      component: () => import (/* webpackChunkName: "settings" */ "./views/SettingsView.vue")
    },
    {
      path: "settings/paytype/:id",
      name: "payroll.settings.paytype",
      meta: { breadcrumb: 
        {
          label: 'Pay Type Details',
          parent:'payroll.settings'
        } 
      },
      component: () => import (/* webpackChunkName: "settings" */ "./views/PayTypeDetailView.vue")
    },
    {
      path: "settings/ratetype/:id",
      name: "payroll.settings.ratetype",
      meta: { breadcrumb: 
        {
          label: 'Rate Type Details',
          parent:'payroll.settings'
        } 
      },
      component: () => import (/* webpackChunkName: "settings" */ "./views/RateTypeDetailView.vue")
    },
    {
      path: "settings/position/:id",
      name: "payroll.settings.position",
      meta: { breadcrumb: 
        {
          label: 'Position Details',
          parent:'payroll.settings'
        }
      },
      component: () => import (/* webpackChunkName: "settings" */ "./views/PositionDetailView.vue")
    },
    {
      path: "import-wizard"
      , name: "importWizard"
      , component: () => import (/* webpackChunkName: "stores" */ "./views/EmployeeImportWizard.vue" ),
      meta: {
        breadcrumb:{
          label:'Import Wizard',
          parent:'payroll.employees'
        }
      }
    },
  ]
};

export default router => {
  router.addRoute(moduleRoute)
  return moduleRoute
}