import router from "./router";

const modules = {};

const registerModule = (name, module) => {
  if (module.router) {
    const routeConfig = module.router(router);
    if (routeConfig) {
      modules[name] = modules[name] || {};
      modules[name].router = routeConfig;
    }
  }

  if (module.featuredNavItems) {
    modules[name] = modules[name] || {};
    modules[name].featuredNavItems = module.featuredNavItems;
  }

  if (module.configurationNavItems) {
    modules[name] = modules[name] || {};
    modules[name].configurationNavItems = module.configurationNavItems;
  }
};

export const registerModules = modules => {
  Object.keys(modules).forEach(moduleKey => {
    const module = modules[moduleKey];
    registerModule(moduleKey, module);
  });
};

export const getModuleRoutes = () => Object.values(modules).map(m => m.router);
export const getFeatureNavItems = moduleName => modules[moduleName]?.featuredNavItems || [];
export const getConfigurationNavItems = moduleName => modules[moduleName]?.configurationNavItems || [];
