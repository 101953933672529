import router from "./router";

const configurationNavItems = [
    {
        label: 'Settings',
        icon: 'mdi-cogs',
        to:'/analytics/settings'
    }
]

export default {
    router
    , configurationNavItems
}