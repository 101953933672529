// stores/moduleStore.js
import { defineStore } from 'pinia';
import { ref } from 'vue'

const STORE_NAME = 'module';

export const useModuleStore = defineStore(STORE_NAME, () => {
    
    const currentModule = ref("")

    const setCurrentModule = (moduleName) => {
        currentModule.value = moduleName;
    };

    const getCurrentModule = () => {
        return currentModule.value;
    };

    return {
        setCurrentModule,
        getCurrentModule,
    };
});
