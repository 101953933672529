import { compile } from "vue";

const moduleRoute = {
  path: "/auth",
  meta: {
    module: "auth",
    requiresAuth: false
  },
  component: () => import(/* webpackChunkName: "login" */"./authModule.vue"),
  children: [
    {
      path: "login",
      component: () => import(/* webpackChunkName: "login" */"./views/LoginView.vue"),
    },
    {
      path: "/forgot-password",
      component: () => import( /* webpackChunkName: "forgotPassword" */"./views/ForgotPasswordView.vue")
    },
    {
      path: "/reset-password/:userId",
      component: () => import( /* webpackChunchName: "resetPassowrd" */"./views/ResetPasswordView.vue")
    }
  ]
};

export default router => {
  router.addRoute(moduleRoute);
}