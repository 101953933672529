import moment from 'moment'

export function useUtil() {
    const calcuateHours = (startDate, endDate) => {
        const diff = ( endDate.getTime() - startDate.getTime() ) / 1000
        return Math.abs( diff / ( 60 * 60 ) )
    }

    const getDatesArray = (startDate, endDate) => {
        const dates = []
        const start = moment(startDate)
        const end = moment(endDate)
        while ( end.diff(start,'days') >= 0 ) {
            dates.push( start.format('M/D/yyyy') )
            start.add( 1, 'day' )
        }
        return dates
    }

    const getDatesInLastQuarterToDate = () => {
        const end = moment()
        const start = moment().add(-1, 'quarter').startOf('quarter')
        return getDatesArray( start, end )
    }

    const parseJwt = (token) => {
        return JSON.parse(window.atob(token.split('.')[1]));
    }

    return { calcuateHours, getDatesArray, getDatesInLastQuarterToDate, parseJwt }
}