// Styles
import '@mdi/font/css/materialdesignicons.css'
import { VTextField } from 'vuetify/components/VTextField'
import { VSelect } from 'vuetify/components/VSelect'
import { VAutocomplete } from 'vuetify/components/VAutocomplete'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const razayyaTheme  = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#17445C',
    'primary-darken-1': '#012f44',
    'primary-lighten-1': '#356a88',
    'primary-lighten-2': '#5d8dac',
    secondary: '#FFC655',
    'secondary-darken-1': '#fab04e',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    quickbooks: '#0e8900',
    pos: '#485464',
    razayyaGrey: '#333333'
  },
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'razayyaTheme',
    themes: {
      razayyaTheme,
    },
  },
  components: {
    VTextField,
    VSelect,
    VAutocomplete
  }
})
