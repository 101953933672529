<template>
  <v-app-bar scroll-behavior="elevate">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="$emit('menu-btn-click')"/>
    </template>
    <v-menu v-if="currentUser?.modules && currentUser?.modules.length > 0">
      <template v-slot:activator="{ props }" >
        <v-btn v-bind="props">
          <template v-slot:append>
            <v-icon>mdi-menu-down</v-icon>
          </template>
          {{ selectedModule || 'Select Module' }}
        </v-btn>
      </template>
      <v-list v-model:selected="selectedModule">
          <v-list-item
              v-for="item in currentUser.modules"
              :value="item.name"
              :key="item.id"
              :title="item.name"
              :prepend-icon="item.icon ?? 'mdi-view-module'"
              @click="selectModule(item)"
          />
      </v-list>
    </v-menu>
    <v-spacer />
    <v-btn variant="plain" class="text-none" prepend-icon="mdi-message-question-outline" @click="$router.push('/support')">Contact Support</v-btn>
    <v-btn variant="plain" class="text-none" prepend-icon="mdi-information-slab-circle-outline" @click="$router.push('/help')">Help</v-btn>
    <v-list-item>
      <UserLogin />
    </v-list-item>
  </v-app-bar>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import UserLogin from "@/modules/authModule/components/UserLogin.vue";
import { useAuthStore } from '@/stores';
import { useModuleStore } from '@/stores/moduleStore';
import { useRouter } from 'vue-router'

const router = useRouter()

const { currentUser } = toRefs( useAuthStore() )
const { getCurrentModule, setCurrentModule } = useModuleStore();

const selectedModule = computed(() => getCurrentModule());

const selectModule = ( module ) => {
  setCurrentModule( module.name )
  router.push( module.route )
}

</script>