<template>
  <v-app>
    <v-system-bar color="secondary" v-if="false">
      <v-spacer></v-spacer>
      <span class="my-2">System Alert of some kind.  i.e. Site will be down for maintenance on 8/12 9:00 pm ET for approx. 8hrs</span>
      <v-spacer></v-spacer>
    </v-system-bar>

    <TheNavigationDrawer :expanded="expanded" />

    <TheAppBar @menu-btn-click="toggleDrawer()"/>

    <v-main style="margin-left:8px;" @scroll="handleScroll">
      <RouterView />
    </v-main>

    <v-footer class="d-flex align-end justify-end">
      <div class="text-caption ma-2 pa-2 mt-auto">
        <v-icon>mdi-copyright</v-icon> {{ new Date().getFullYear() }} | <strong>Razayya Financial</strong>
      </div>
    </v-footer>
  </v-app>
</template>

<script setup>

import { ref } from 'vue';
import { useDisplay } from 'vuetify';
import TheAppBar from './components/TheAppBar.vue';
import TheNavigationDrawer from "./components/TheNavigationDrawer.vue";

const { smAndDown } = useDisplay();

const expanded = ref(!smAndDown.value);

const toggleDrawer = () => {
  expanded.value = !expanded.value;
};

</script>

<style>
ul.tooltip-list li {
  list-style-type: none;
}

.cursor-pointer {
  cursor: pointer;
}
</style>