const moduleRoute = {
  path: "/admin",
  name: "admin",
  redirect: '/admin/tenants',
  meta: {
    module: "admin",
    requiresAuth: true
  },
  component: () => import("./adminModule.vue"),
  children: [
    {
      path: "/admin/users",
      name: "users",
      meta: { 
        showInNav: true,
        icon: 'mdi-account-multiple',
        breadcrumb: 
        {
          label: 'Users',
        } 
      },
      component: () => import(/* webpackChunkName: "users" */'./views/UsersListView.vue')
    },
    {
      path: "/admin/users/:id",
      name: "user-details",
      meta: { 
        breadcrumb: 
        {
          label: 'User Details',
          parent: 'users'
        } 
      },
      component: () => import (/* webpackChunkName: "user-details" */ "./views/UserDetailView.vue"),
    },
    {
      path: "/admin/tenants",
      name: "tenants",
      meta: { 
        showInNav: true,
        icon: 'mdi-domain',
        breadcrumb: 
        {
          label: 'Tenants',
        } 
      },
      component: () => import(/* webpackChunkName: "users" */'./views/TenantsListView.vue')
    },
    {
      path: "/admin/tenants/:id",
      name: "tenant-details",
      meta: { breadcrumb: 
        {
          label: 'Tenant Details',
          parent: 'tenants'
        } 
      },
      component: () => import (/* webpackChunkName: "user-details" */ "./views/TenantDetailView.vue"),
    }
  ]
};


export default router => {
  router.addRoute(moduleRoute)
  return moduleRoute
}