const rootPath = "/analytics"

const moduleRoute = {
    path: rootPath,
    name: "analytics",
    redirect: rootPath + '/settings',
    meta: {
      module: "analytics",
      requiresAuth: true,
      title: "Razayya Financial | Insights | Analytics"
    },
    component: () => import(/* webpackChunkName: "analytics" */ "./analyticsModule.vue" ),
    children: [
        {
            path: rootPath + '/home',
            name: 'analyticsHome',
            meta: {
                showInNav: true,
                icon: 'mdi-home',
                breadcrumb: {
                    label: 'Home'
                }
            },
            component: () => import ("./views/HomeView.vue")
        },
        {
            path: rootPath + "/report/:reportId",
            name: 'report',
            component: () => import("./views/ReportView.vue")
        },
        {
            path: rootPath + "/settings",
            name: 'settings',
            component: () => import ("./views/HomeView.vue")
        }
    ]
}

export default router => {
    router.addRoute(moduleRoute)
    return moduleRoute
}