import { defineStore } from 'pinia'
import { ref } from 'vue'

const STORE_NAME = 'gridState'

export const useGridStateStore = defineStore(STORE_NAME, () => {
    const gridState = ref(JSON.parse(sessionStorage.getItem(STORE_NAME)) || {})

    // Functions to set and get grid state
    const setGridState = (pageKey, newState) => {
        gridState.value[pageKey] = newState;
        sessionStorage.setItem(STORE_NAME, JSON.stringify(gridState.value));
    }

    const getGridState = (pageKey) => {
        if (!gridState.value.hasOwnProperty(pageKey)) {
            // Handle case where property doesn't exist
            return null; // Or you can return any default value you prefer
        }
        return gridState.value[pageKey];
    }

    return { gridState, setGridState, getGridState };
});
