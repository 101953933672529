<template>
  <v-container>
    <v-overlay class="align-center justify-center" close-on-back="false" persistent>
        <v-progress-circular size="50" color="primary" indeterminate></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script setup>
  import { toRefs, watch, onBeforeMount } from 'vue'
  import { useAuthStore } from "@/stores"
  import { useRouter } from "vue-router"

  const { isLoggedIn, currentUser } = toRefs( useAuthStore() )

  const router = useRouter()

  onBeforeMount(() => {  
    redirectToModule()
  })

  watch( currentUser, () => {
    if( currentUser.value ){
      redirectToModule()
    }
  })

  const redirectToModule = () => {
    const modules = currentUser.value?.modules ?? []

    if( modules && modules.length > 0 )
    {
      router.push(modules[0].route)
    }
  }

</script>