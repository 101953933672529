import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useBackend } from '@/composables/backend'

const STORE_NAME = 'auth'
const baseUri = process.env.VUE_APP_API_URL

let isRefreshing = false

export const useAuthStore  = defineStore(STORE_NAME, () => {
    
    const auth = ref(JSON.parse(localStorage.getItem(STORE_NAME)))

    const lastModule = ref(JSON.parse(localStorage.getItem("lastModule")));

    function saveLastModule(moduleName) {
        lastModule.value = moduleName;
        localStorage.setItem("lastModule", JSON.stringify(lastModule.value))
    }

    function getLastModule() {
        return lastModule.value;
    }

    const { item: currentUser, getItem } = useBackend('user')

    const refreshTokenTimeout = ref(null)
    
    const isLoggedIn = computed( () => {
        return ( auth.value !== null )
    })

    async function login(username, password){
        
        try{
            const res = await fetch( `${baseUri}/user/login`, {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
    
            if( res.ok )
            {
                auth.value = await res.json()
                localStorage.setItem(STORE_NAME, JSON.stringify(auth.value))
                startRefreshTokenTimer()
                getItem('current')
                return auth.value
            }
            else {
                throw Error('Incorrect login credentials')
            }
        }
        catch (er){
            if( er.message === 'Incorrect login credentials'){
                throw Error('Inccorrect username or password.')
            }
            else {
                throw Error('Failed to communicate with server.  Please contact support@razayya.com')
            }
        }
        
    }

    function logout(){
        auth.value = null
        localStorage.removeItem(STORE_NAME)
        stopRefreshTokenTimer()
        currentUser.value = null
    }

    async function refreshToken(){
        // If a refresh is already in progress, don't trigger another one
        if (isRefreshing) {
            console.log("Refresh already in progress.");
            return;
        }

        console.log(Date.now(), "refreshing Access Token.");

        isRefreshing = true; // Set the flag to indicate that a refresh is in progress

        try{
            const res = await fetch( `${baseUri}/user/refresh`, {
                method: 'POST',
                body: JSON.stringify({ AccessToken: auth.value.accessToken, RefreshToken: auth.value.refreshToken }),
                headers: { 'Content-Type': 'application/json' }
            })

            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
           
            auth.value = await res.json()
            localStorage.setItem(STORE_NAME, JSON.stringify(auth.value))
            startRefreshTokenTimer()
            getItem('current')
            return auth.value
        }
        catch {
            console.log("Cannot communicate with server")
            auth.value = null
            localStorage.removeItem(STORE_NAME)
            stopRefreshTokenTimer()
            currentUser.value = null
        }
        finally {
            isRefreshing = false; // Reset the flag when the refresh process is complete
        }
    }

    function startRefreshTokenTimer() {
        const jwtBase64 = auth.value.accessToken.split('.')[1]
        const jwtToken = JSON.parse(atob(jwtBase64))

        const expires = new Date(jwtToken.exp * 1000)
        console.log( expires )
        const timeout = expires.getTime() - Date.now() - ( 60 * 5000 )

        refreshTokenTimeout.value = setTimeout(refreshToken, timeout )
    }

    function stopRefreshTokenTimer(){
        clearTimeout( refreshTokenTimeout.value )
    }

    return { auth: computed(() => { return auth.value }), currentUser: computed(() => currentUser.value ), isLoggedIn, login, logout, refreshToken, refreshTokenTimeout, saveLastModule, getLastModule }
})