import { RouterView } from "vue-router";

const moduleRoute = {
    path: "/financial-modeling",
    name: "financial-modeling",
    redirect: "/financial-modeling/scenarios",
    meta: {
      module: "modeling",
      title: 'Razayya Financial | Insights | Financial Modeling',
      requiresAuth: true,
      breadcrumb: 
        {
          label: 'Financial Modeling',
        } 
    },
    component: () => import(/* webpackChunkName: "reconcile" */ "./financialModelingModule.vue"),
    children: [
      {
        path: "/financial-modeling/scenarios",
        name: "financial-modeling.scenarios",
        component: () => import('./views/ScenariosListView.vue'),
        meta: {
          breadcrumb: {
            label: 'Scenarios',
            parent: 'financial-modeling'
          }
        }
      },
      {
        path: "/financial-modeling/scenarios/inputs",
        name: "financial-modeling.scenarios.inputs",
        component: () => import('./views/ScenarioInputFilesListView.vue'),
        meta: {
          breadcrumb: {
            label: 'Input Files',
            parent: 'financial-modeling.scenarios'
          }
        }
      },
      {
        path: "/financial-modeling/test/scenarios",
        name: "modeling.test.scenarios",
        component: RouterView,
        meta:{
          icon: "mdi-briefcase",
          showInNav: true,
          breadcrumb:{
            label: "Scenarios"
          }
        },
        children:[
          {
            path: "/financial-modeling/test/scenarios/home",
            name: "modeling.test.scenarios.home",
            component: () => import('./views/ScenariosListView.vue'),
            meta:{
              icon: "mdi-view-list",
              breadcrumb:{
                label:'Home',
                parent:'modeling.test.scenarios'
              }
            }
          }
        ]
      },
      {
        path: "/financial-modeling/test/inputs",
        name: "modeling.test.inputs",
        component: RouterView,
        meta:{
          showInNav: true,
          icon: "mdi-import",
          breadcrumb:{
            label: "Inputs"
          }
        },
        children:[
          {
            path: "/financial-modeling/test/inputs/home",
            name: "modeling.test.inputs.home",
            component: () => import('./views/ScenarioInputFilesListView.vue'),
            meta:{
              icon: "mdi-file-multiple",
              breadcrumb:{
                label:'Input Files',
                parent:'modeling.test.inputs'
              }
            }
          }
        ]
      }
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute)
    return moduleRoute
  }