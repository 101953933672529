import router from "./router";
import { useAuthStore } from '@/stores'

const featuredNavItems = [
    {
        label: "Jobs Dashboard",
        icon: "mdi-monitor-dashboard",
        onClick: () => {
            
            const { auth } = useAuthStore()

            console.log(auth)
            
            const url = new URL(process.env.VUE_APP_API_URL)
            const dashboardUrl = `${url.protocol}//${url.host}/hangfire?access_token=${auth.accessToken}`
            window.open(dashboardUrl, '_blank');
        }
    }
]

const configurationNavItems = [
    {
        label: "Settings",
        icon: "mdi-cogs",
        to:"/admin/tenants/1"
    }
]

export default {
    router,
    featuredNavItems,
    configurationNavItems
}