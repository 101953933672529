import router from "./router";

const configurationNavItems = [
    {
        label: "Import Mapping",
        icon: "mdi-cog-sync",
        to:"/revenue/import-configuration"
    }
  ]

export default {
    router,
    configurationNavItems
}